<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      Detalhes do Bilhete
    </v-subheader>

    <v-row class="mb-3">
      <v-col cols="12" sm="9">
        <v-card>
          <v-card-title
            class="primary white--text d-flex justify-space-between"
          >
            <span>Bilhete</span>
          </v-card-title>
          <v-card-text class="pa-7">
            <v-row>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Número: </span>
                <span class="black--text">{{ dados.id }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Categoria: </span>
                <span class="black--text text-capitalize">{{
                  dados.tipo_venda.toLowerCase()
                }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Data da Compra: </span>
                <span class="black--text">{{ dados.datahora }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Empresa: </span>
                <span class="black--text">{{
                  dados.bpe.infBPe.emit.xNome
                }}</span>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="primary--text">Status: </span>
                <span class="black--text text-capitalize">{{
                  dados.status_venda.toLowerCase()
                }}</span>
              </v-col>
              <v-col cols="12" sm="6" v-if="dados.remarcacao">
                <span class="primary--text">Tipo: </span>
                <span class="black--text text-capitalize">Remarcação</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-7">
          <v-card-title
            class="primary white--text d-flex justify-space-between"
          >
            <span>Dados da Viagem</span>
          </v-card-title>
          <v-card-text class="pa-7">
            <v-row>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Linha: </span>
                <span class="black--text">{{ dados.linha }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Serviço: </span>
                <span class="black--text text-capitalize">{{
                  dados.servico.toLowerCase()
                }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Origem: </span>
                <span class="black--text text-capitalize">{{
                  dados.origem.toLowerCase()
                }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Destino: </span>
                <span class="black--text text-capitalize">{{
                  dados.destino.toLowerCase()
                }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Data da Viagem: </span>
                <span class="black--text">{{ dados.data_hora_viagem }}</span>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <span class="primary--text">Poltrona: </span>
                <span class="black--text">{{ dados.poltrona }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-7">
          <v-card-title
            class="primary white--text d-flex justify-space-between"
          >
            <span>Dados do Cliente</span>
          </v-card-title>

          <v-card-text class="pa-7">
            <v-row>
              <v-col cols="12" sm="6">
                <span class="primary--text">Nome do Passageiro: </span>
                <span class="black--text text-capitalize">{{
                  dados.bpe.infBPe.infPassagem.infPassageiro.xNome.toLowerCase()
                }}</span>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="primary--text">Documento: </span>
                <span class="black--text">{{
                  dados.bpe.infBPe.infPassagem.infPassageiro.nDoc
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-7">
          <v-card-title
            class="primary white--text d-flex justify-space-between"
          >
            <span>Bilhete Eletrônico</span>
          </v-card-title>

          <v-card-text class="pa-7">
            <v-row>
              <v-col cols="12" sm="12">
                <span class="primary--text">Link de Acesso ao BPe:</span>
                <br />
                <a :href="dados.link" target="_blank" class="black--text">{{
                  dados.link
                }}</a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="3">
        <v-card>
          <v-card-title
            class="primary white--text d-flex justify-space-between"
          >
            <span>Valores</span>
          </v-card-title>
          <v-card-text class="pa-7">
            <v-row>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Total: </span>
                <span class="black--text title">
                  R$ {{ dados.valor_apagar }}
                </span>
              </v-col>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Tarifa: </span>
                <span class="black--text">{{ dados.valor_total }}</span>
              </v-col>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Seguro: </span>
                <span class="black--text">{{ dados.seguro }}</span>
              </v-col>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Taxa de Embarque: </span>
                <span class="black--text">{{ dados.taxaembarque }}</span>
              </v-col>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Pedágio: </span>
                <span class="black--text">{{ dados.pedagio }}</span>
              </v-col>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Outros: </span>
                <span class="black--text">{{ dados.outros }}</span>
              </v-col>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Desconto: </span>
                <span class="black--text">{{ dados.desconto }}</span>
              </v-col>
              <v-col cols="12" sm="12" class="py-2">
                <span class="primary--text">Forma de Pagamento: </span>
                <span class="black--text text-capitalize">{{
                  dados.formapagamento.toLowerCase()
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BackArrow from "../../../../components/BackArrow/index.vue";

export default {
  components: { BackArrow },
  props: ["id"],
  data() {
    return {
      dados: [],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.$http.get(`/caixa/bilhete/${this.id}`).then((r) => {
        this.dados = r.data[0];
        this.$store.dispatch("activeLoader", false);
      });
    },
  },
};
</script>

<style lang="css">
.container-form {
  position: relative;
  min-height: calc(100vh - 70px);
}
</style>
